
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










































.breadcrumb + .m-xl {
  margin-top: 0;
}

.breadcrumb.wrapper {
  max-width: 100%;
}

.video {
  ::v-deep .video__content__title {
    flex-direction: column;
  }

  ::v-deep .icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}

.faq-details {
  position: relative;
  max-width: 100%;

  .container-sticky {
    right: 4.1666666667%;

    @media only screen and (min-width: 85em) {
      right: 8.3333333%;
    }
  }

  ::v-deep {
    .main-title__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 24px, m: 48px));
      text-transform: none;
    }

    .wysiwyg ul,
    .wysiwyg ol {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }

    a.action--btn--link--icon--arrow {
      margin-bottom: 3rem;
    }

    .accordion {
      padding: 0 !important;
    }

    p.blockTitle {
      padding-left: 45px;
      font-size: 25px;
      font-weight: bolder;
      background-size: 30px auto;
      background-repeat: no-repeat;
      background-position: left;
    }

    div.faqAstuce {
      background-color: #fff4e0;
      padding: 25px;
      margin-bottom: 30px;
      border-left: 4px solid $c-yellow;
    }

    div.faqAstuce p.blockTitle {
      color: $c-yellow;
      background-image: url('../assets/svg/icons/faq-astuce.svg');
    }

    div.faqAstuce a {
      color: $c-yellow;
      text-decoration: underline;
    }

    div.faqAstuce a[class*='action--'][class*='--btn'] {
      color: $c-white;
      text-decoration: none;
    }

    div.faqInfo {
      background-color: #ebf6f2;
      padding: 25px;
      margin-bottom: 30px;
      border-left: 4px solid $c-green;
    }

    div.faqInfo p.blockTitle {
      color: $c-green;
      background-image: url('../assets/svg/icons/faq-info.svg');
    }

    div.faqInfo a {
      color: $c-green;
      text-decoration: underline;
    }

    div.faqInfo a[class*='action--'][class*='--btn'] {
      color: $c-white;
      text-decoration: none;
    }

    div.faqAttention {
      background-color: #fce9e2;
      padding: 25px;
      margin-bottom: 30px;
      border-left: 4px solid $c-red;
    }

    div.faqAttention p.blockTitle {
      color: $c-red;
      background-image: url('../assets/svg/icons/faq-attention.svg');
    }

    div.faqAttention a {
      color: $c-red;
      text-decoration: underline;
    }

    div.faqAttention a[class*='action--'][class*='--btn'] {
      color: $c-white;
      text-decoration: none;
    }
  }
}

.faq-details-inner {
  display: flex;
  flex-direction: column;
  max-width: 168rem;

  @include mq(l) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
}

.faq__nav {
  width: 100%;
  padding: 4rem col(1, 24) 3rem col(2, 24);
  border-bottom: 1px solid $c-gray-light;

  @include mq(l) {
    width: col(9, 24);
    padding: 8rem col(1, 24) 11rem col(2, 24);
    border-right: 1px solid $c-gray-light;
    border-bottom: 0;
  }

  @include mq(wrapper) {
    width: col(7, 24);
  }
}

.faq__nav__back {
  a {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    color: $c-black;
    font-size: 1.8rem;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.3s;

    .faq__content & {
      display: inline-flex;
      color: $c-gray;

      svg {
        fill: $c-gray;
        transform: rotate(90deg);
      }
    }

    svg {
      margin-right: 1rem;
      transform: rotate(-180deg);
      transition: all 0.3s;
    }

    &:hover {
      color: $c-main-primary;

      svg {
        fill: $c-main-primary;
      }
    }
  }
}

.faq__content {
  width: 100%;
  padding: 4rem col(2, 24) 3rem col(1, 24);

  @include mq(l) {
    width: col(15, 24);
    padding: 8rem col(2, 24) 11rem col(1, 24);
  }

  @include mq(wrapper) {
    width: col(17, 24);
  }
}

.faq__item {
  @extend %fw-medium;
  @extend %border-radius;

  list-style-type: none;
  background: $c-white;
  border: 1px solid $c-gray-light;
  transition: all 0.3s;

  ::v-deep {
    .action__label {
      color: $c-black !important; // stylelint-disable-line declaration-no-important
    }

    .action__icon {
      fill: $c-black;
    }
  }

  &::before {
    display: none;
  }

  &:hover {
    border: 1px solid $c-white;
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }

  ::v-deep [class*='action--link'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 20px, m: 30px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 20px, m: 40px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 20px, m: 30px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 20px, m: 40px));

    display: flex;
    justify-content: space-between;
  }
}
