
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














.main-title {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.main-title__title {
  margin: 0;
}

.main-title__date {
  @extend %fw-light;

  width: 100%;
  margin: 0;
  color: $c-gray;
  font-size: 1.3rem;
  letter-spacing: 0.5px;
}

.main-title hr {
  margin: 1.5rem 0;
  border-bottom: 2px solid $c-black;
}

.main-title__inner {
  .has-button & {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
